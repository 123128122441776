
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupsItem from "@/components/commodity-groups/CommodityGroupsItem.vue";
import brand from "@/services/brand";
import BaseError from "@/components/ui/BaseError.vue";
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';

export default defineComponent({
  name: 'CmBrandModalEdit',
  components: { ModalWrapper, CommodityGroupsItem, BaseError, BaseCheckbox },
  data: () => ({
    changesCommodityGroup: {
      brand: null as any,
      certUse: false as boolean
    } as any,
    error: null as any
  }),
  props: {
    group: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    group: {
      handler() {
        this.setValueForEdit()
      },
      deep: true
    }
  },
  computed: {
    checkValues(): any {
      return Object.values(this.changesCommodityGroup).every(el => el === null)
    }
  },
  methods: {
    setValueForEdit() {
      if (this.group) {
        this.changesCommodityGroup.certUse = this.group.certUse
      }
    },
    changeValue(field: string, value: string) {
      this.changesCommodityGroup[field] = value
    },
    async saveChanges() {
      const payload = {
        ...this.group,
        ...(this.changesCommodityGroup.brand && {brand: this.changesCommodityGroup.brand}),
        certUse: this.changesCommodityGroup.certUse
      }

      try {
        const response = await brand.updateBrand(this.group.brandId, payload);
        this.$emit('reload', response.data);
        this.$emit('closeModal')
      } catch (e) {
        this.error = `Название ${this.changesCommodityGroup.brand} уже существует.`
      }
    },
    async changeBrandFocus(focus: boolean) {
      const response = await brand.setFocusBrand(this.group.brandId);
      this.$emit('reload', response.data)
    }
  },
  mounted() {
    this.setValueForEdit()
  }
})

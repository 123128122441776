
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupsItem from '@/components/commodity-groups/CommodityGroupsItem.vue';
import brand from "@/services/brand";
import BaseError from "@/components/ui/BaseError.vue";
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";

export default defineComponent({
  name: 'AddBrandModal',
  emits: ['reload'],
  components: { ModalWrapper, CommodityGroupsItem, BaseError, BaseCheckbox },
  data: () => ({
    brandName: '' as string,
    error: null as any,
    directions: [
      { key: 'OE', value: 'OE', checked: false },
      { key: 'CM', value: 'CM', checked: false }
    ],
    certUse: false
  }),
  props: {
    direction: {
      type: String,
      required: true
    }
  },
  computed: {
    checkValues(): any {
      return !this.brandName.trim().length
    },
    getDirection(): any {
      const checkedDirection = this.directions.filter((direction: any) => direction.checked)
      return checkedDirection && checkedDirection[0] && checkedDirection[0].value
    }
  },
  methods: {
    clickDirection(item: any) {
      this.directions.forEach((o: any) => {
        o.checked = o.key === item.key
      })
      this.error = null
      this.brandName = ''
    },
    async addBrand() {
      const payload = {
        direction: this.getDirection,
        brand: this.brandName,
        certUse: this.certUse,
      }

      if (this.brandName.match(/[^a-zA-Z().\s]/)) {
          this.error = `Название бренда может содержать "." точку, "()" (скобки), A-Z (буквы).`
          return
      }

      try {
        await brand.addNewBrand(payload)
        this.$emit('reload')
      } catch (e) {
        this.error = `Бренд <span style="color: #4F5058;">${this.brandName}</span> уже существует.`
      }
    },
    closeModal() {
      this.error = null
      this.brandName = ''
    }
  },
  watch: {
    brandName() {
      if (this.error && this.error.length) {
        this.error = ''
      }
    }
  },
  mounted() {
    this.directions.forEach((o: any) => {
      o.checked = o.key === this.direction
    })
  }
})

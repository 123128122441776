
import {defineComponent} from 'vue';
import CmCrossBrands from "@/components/cross-brands/CmCrossBrands.vue";
import OeCrossBrands from "@/components/cross-brands/OeCrossBrands.vue";

export default defineComponent({
  name: 'WrapperCrossBrands',
  components: {
    CmCrossBrands,
    OeCrossBrands
  },
  data: () => ({
    menu: [
      {key: 'oe-cross', value: 'OE бренды'},
      {key: 'cm-cross', value: 'CM бренды'},
    ] as object,
    activeMenu: 'oe-cross' as any
  }),
  methods: {
    setActiveMenu(option: any) {
      this.activeMenu = option.key
    }
  }
})

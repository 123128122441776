
import { defineComponent } from 'vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import brand from '@/services/brand';
import params from '@/services/params';
import product from '@/services/product';
import certificates from '@/services/certificates';
import { CertificateTypes } from '@/models/CertificateModel';

export default defineComponent({
  name: 'DropdownSearchCommodityGroups',
  components: {
    BaseInput
  },
  props: {
    placeholder: {
      type: String,
      default: () => 'Поиск по товарным группам'
    },
    type: {
      type: String,
      default: () => 'COMMODITY_GROUPS'
    },
    showButton: {
      type: Boolean,
      default: () => true
    },
    showSearchResult: {
      type: Boolean,
      default: () => true
    },
    visibleProductGroup: Boolean
  },
  data: () => ({
    searchText: '' as any,
    pickedOption: null as any,
    showDropdown: false as any,
    searchResult: null as any
  }),
  methods: {
    dropdownToggle(event: any) {
      if (document.getElementById('dropdown-toggle')?.contains(event.target)) {
        this.showDropdown = true
      } else {
        this.showDropdown = false
      }
    },
    clearInput() {
      this.$emit('clear')
    },
    async update(value: string) {
      this.searchText = value

      let result
      if (this.type === 'COMMODITY_GROUPS') {
        if (this.visibleProductGroup || !value.length) {
          result = await product.findProductGroupByName(value)
        } else {
          const visibleProductGroups = await product.findProductGroupByName(value)
          const invisibleProductGroups = await product.findAllInvisible(value)

          result = {
            content: [
              ...visibleProductGroups.content,
              ...invisibleProductGroups.content
            ]
          }
        }
      }

      if (this.type === 'LOL') {
        result = await certificates.searchDeclarationsByType(value, CertificateTypes.DECLARATION)
      }


      if (this.type === 'COMMODITY_GROUPS_PARAM') {
        result = await params.searchParametersByName(value, 1000)
      }

      if (this.type === 'CROSS_CM') {
        result = await brand.findByPrefix('cm', value)
      }

      if (this.type === 'CROSS_OE') {
        result = await brand.findByPrefix('oe', value)
      }

      if (this.type === 'COMMODITY_SUB_GROUPS') {
        result = await product.findProductSubGroupByNameAndProductId(value, this.$route.params.id)
      }

      if (this.type === 'COMMODITY_SUB_SUB_GROUPS') {
        result = await product.findProductSubSubGroupByNameAndProductId(value, this.$route.params.id)
      }

      this.searchResult = result.content ? result.content : result
      this.$emit('hotFoundProductGroups', {
        searchResult: this.searchResult,
        inputValue: this.searchText
      })

      if (!value.length) {
        this.searchResult = null
        return
      }
    },
    setCommodityGroups() {
      if (this.searchResult && this.searchResult.length) {
        this.$emit('foundCommodityGroups', this.searchResult)
      }
    },
    setCommodityGroupParam(item: any) {
      if (this.type === 'COMMODITY_SUB_GROUPS') {
        this.$emit('pickedOption', item)
      }

      if (this.type === 'COMMODITY_GROUPS_PARAM' && item.name) {
        this.pickedOption = item.name
        this.showDropdown = false
        this.$emit('pickedOption', item)
      }

      if (this.type === 'COMMODITY_SUB_SUB_GROUPS') {
        this.$emit('pickedOption', item)
      }

      if (this.type === 'CROSS_CM' || this.type === 'CROSS_OE') {
        this.$emit('pickedOption', item)
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('click', this.dropdownToggle)
  },
  mounted() {
    window.addEventListener('click', this.dropdownToggle)
  },
})


  import {defineComponent, computed} from 'vue';
  import Spinner from '@/components/Spinner.vue';
  import articles from "@/services/articles";
  import BaseIcon from "@/components/ui/BaseIcon.vue";
  import CommodityGroupsModal from "@/components/modals/CommodityGroupsModal.vue";
  import SearchDropdown from "@/components/search/SearchDropdown.vue";
  import DropdownSearchCommodityGroups from "@/components/dropdown/DropdownSearchCommodityGroups.vue";
  import {ArticleMutationTypes} from "@/store/modules/articles/mutation-types";
  import brand from "@/services/brand";
  import CmBrandModalEdit from "@/components/modals/CmBrandModalEdit.vue";
  import AddBrandModal from "@/components/modals/AddBrandModal.vue";
  import barcodes from '@/services/barcodes';

  export default defineComponent({
    name: 'OeCrossBrands',
    components: {
      Spinner,
      BaseIcon,
      DropdownSearchCommodityGroups,
      CmBrandModalEdit,
      AddBrandModal
    },
    data: () => ({
      isLoadingBrands: false,
      showAddBrandModal: false as boolean,
      searchLetter: 'Все' as any,
      isFoundCommodityGroups: false as boolean,
      pageable: null as any,
      currentGroup: null as any,
      isLoading: true as boolean,
      commodityGroups: [] as any,
      arrSequentialValues: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Ч', 'Ц', 'Ш', 'Щ', 'Ъ', 'Ы', 'Ь', 'Э', 'Ю', 'Я', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    }),
    computed: {
      userCanEditBrand(): any {
        return this.$store.getters.getAllPermissions.edit_brands_article[this.$store.getters.getUserRole]
      }
    },
    methods: {
      previous() {
        if (!!this.pageable.pageNumber) {
          if (this.searchLetter && this.searchLetter !== 'Все') {
            this.setCommodityGroups(this.pageable.pageNumber - 1, {
              action: 'SEARCH_BY_LETTER',
              letter: this.searchLetter
            })

            return
          }
          this.setCommodityGroups(this.pageable.pageNumber - 1)
        }
      },
      next () {
        if ((this.pageable.pageNumber + 1) !== this.pageable.totalPages) {
          if (this.searchLetter && this.searchLetter !== 'Все') {
            this.setCommodityGroups(this.pageable.pageNumber + 1, {
              action: 'SEARCH_BY_LETTER',
              letter: this.searchLetter
            })

            return
          }
          this.setCommodityGroups(this.pageable.pageNumber + 1)
        }
      },
      replaceCommodityGroups(newCommodityGroup: any) {
        this.currentGroup = null
        const index = this.commodityGroups.findIndex((el: any) => el.brandId === newCommodityGroup.id)

        this.commodityGroups.splice(index, 1, {
          brandId: newCommodityGroup.id,
          brand: newCommodityGroup.brand,
          direction: newCommodityGroup.direction,
          certUse: newCommodityGroup.certUse,
        })
      },
      openCommodityGroupsModal(group: any) {
        this.currentGroup = group
        setTimeout(() => {
          let element = document.getElementById(`modal-edit-brand-cm`)!
          element.style.display = 'flex'
        }, 0)
      },
      closeCommodityGroupsModal() {
        let element = document.getElementById(`modal-edit-brand-cm`)!
        element.style.display = 'none'
      },
      async getCommodityGroups(page = 0, data?: any) {
        if (data && data.action === 'SEARCH_BY_LETTER') {
          const response = await brand.findByPrefix('oe', data.letter, page)
          this.isFoundCommodityGroups = false

          this.pageable = {
            ...response.pageable,
            totalPages: response.totalPages
          }

          return response.content
        }
        const newCommodityGroups = await brand.findCmAndOeBrands(page, 'oe').then((response: any) => {
          this.pageable = {
            ...response.pageable,
            totalPages: response.totalPages
          }

          return response.content
        })

        return newCommodityGroups
      },
      async setCommodityGroups(page = 0, data?: any) {
        this.isFoundCommodityGroups = false
        this.isLoading = true
        this.commodityGroups = await this.getCommodityGroups(page, data)
        this.isLoading = false
      },
      setFoundCommodityGroups(newCommodityGroups: any) {
        this.isFoundCommodityGroups = true
        this.commodityGroups = newCommodityGroups
      },
      clearFoundCommodityGroups() {
        this.setCommodityGroups(this.pageable.pageNumber)
        this.searchLetter = 'Все'
      },
      async setSearchByLetter(letter: string) {
        this.searchLetter = letter
        this.setCommodityGroups(0, {
          action: 'SEARCH_BY_LETTER',
          letter
        })

      },
      clearSearchLetter() {
        this.searchLetter = 'Все'
        this.setCommodityGroups(0)
      },
      goToPage(url: string, group: any) {
        this.$store.commit(ArticleMutationTypes.SET_GROUP, group.desc || '')

        localStorage.setItem('group', JSON.stringify({
          id: group.id,
          value: group.desc
        }))

        this.$router.push(url)
      },
      openModalAddBrand() {
        setTimeout(() => {
          let element = document.getElementById(`modal-add-brand`)!
          element.style.display = 'flex'
        }, 0)
      },
      async downloadBrands() {
        this.isLoadingBrands = true
        const table = await brand.exportBrandsByDirection("OE") as any
        const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `template.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isLoadingBrands = false
      }
    },
    async mounted() {
      this.setCommodityGroups()
    },
  })
